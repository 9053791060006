<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="items"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    :button-required-permissions="requiredPermissions"
    :store-name="storeName"
    table-css-class="right-now-table"
  >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.title"
        class="right-now-table__column--overflow"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.text"
        class="right-now-table__column--overflow"
      />
      <CreatedByAndAtColumn
        :title-header="tableHeaders[3].name"
        :created-at="item.publishedAt"
      />
      <CreatedByAndAtColumn
        :title-header="tableHeaders[4].name"
        :created-at="item.modifiedAt"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import CreatedByAndAtColumn from '@/components/table/columns/CreatedByAndAtColumn'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'NewsOfTheDayTable',
  props: {
    items: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    },
    storeName: {
      type: String,
      required: false,
      default: 'newsOfTheDay'
    }
  },
  components: {
    CreatedByAndAtColumn,
    TitleColumn,
    AbstractDataTable
  },
  data () {
    return {
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.RIGHT_NOW_PERMISSIONS
    }
  },
  computed: {
    tableHeaders () {
      return [
        { name: this.$t('ID') },
        { name: this.$t('rightNow.title') },
        { name: this.$t('rightNow.text') },
        { name: this.$t('rightNow.published_at') },
        { name: this.$t('rightNow.modified_at') },
        { name: this.$t('actions') }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.right-now-table {
  ::v-deep &__thead,
  ::v-deep &__tr {
    @include bp(12) {
      grid-template-columns: rem(80px) rem(240px) auto rem(130px) rem(130px) min(60px);
    }
    @include bp(14) {
      grid-template-columns: rem(80px) rem(240px) auto rem(130px)  rem(130px) rem(138px);
    }
  }

  &__column--overflow {
    @include bp(12) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis
    }
  }
}
</style>
