<template>
  <nav class="right-now-navigation">
    <router-link v-for="(item, index) in navigation" :to="item.to" :key="index" class="right-now-navigation__link">
      {{ $t('route.' + item.alias) }}
    </router-link>
  </nav>
</template>

<script>

export default {
  name: 'RightNowNavigation',
  data () {
    return {
      navigation: [
        { alias: 'news_of_the_day_list', to: { name: 'news_of_the_day_list' } },
        { alias: 'right_now_list', to: { name: 'right_now_list' } }
      ]
    }
  }
}

</script>
<style lang="scss" scoped>
.right-now-navigation {
  margin: 0 rem(30px);

  &__link {
    color: #8A96AC;
    padding: rem(10px) 0;
    margin-right: rem(10px);

    &:last-of-type {
      margin-right: 0;
    }
  }

  .router-link-active {
    border-bottom: 3px solid #1e88e5;
    color: #1e88e5;
  }
}
</style>
