<template>
  <app-module-view>
    <template v-slot:navigation>
      <right-now-navigation/>
    </template>

    <template v-slot:body>
      <div class="card news-of-the-day-filter">
        <FilterNewsOfTheDay/>
      </div>

      <Preloader v-if="callingAPI"/>

      <NewsOfTheDayTable
        :items="newsOfTheDay"
        :totalCount="totalCount"
        :current-page="page"
        :on-page-change="setPageAndGetRecords"
      />
    </template>
  </app-module-view>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import FilterNewsOfTheDay from '@/components/filter/FilterNewsOfTheDay'
import NewsOfTheDayTable from '@/components/table/NewsOfTheDayTable'
import appModuleView from '@/components/ModuleView'
import RightNowNavigation from '@/views/rightNow/components/RightNowNavigation'

export default {
  name: 'NewsOfTheDayListView',
  components: {
    RightNowNavigation,
    appModuleView,
    FilterNewsOfTheDay,
    Preloader,
    NewsOfTheDayTable
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    newsOfTheDay () {
      return this.$store.getters['newsOfTheDay/list']
    },
    totalCount () {
      return this.$store.getters['newsOfTheDay/totalCount']
    },
    page () {
      return this.$store.getters['newsOfTheDay/page']
    }
  },
  mounted () {
    this.getNewsOfTheDay()
  },
  methods: {
    async getNewsOfTheDay () {
      await this.$store.dispatch('newsOfTheDay/fetch')
      this.dataLoaded = true
    },
    setPageAndGetRecords (page) {
      this.$store.commit('newsOfTheDay/setPage', page)
      this.getNewsOfTheDay()
    }
  }
}
</script>
<style lang="scss" scoped>
.news-of-the-day-filter {
  padding: rem(30px);
}
</style>
