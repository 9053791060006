<template>
  <AbstractFilter
    :on-filter="filterNewsOfTheDay"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    filter-panel-class="filter-panel-news-of-the-day"
    create-new-route-name="news_of_the_day_new"
  >
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('rightNow.title')"
    />
    <Input
      v-model="filter.text"
      id="filter_text"
      :label="$t('rightNow.text')"
    />
    <Datepicker
      type="date"
      v-model="filter.publishedAt"
      id="filter_publishedAt"
      :label="$t('rightNow.published_at')"
    />
    <Datepicker
      type="date"
      v-model="filter.modifiedAt"
      id="filter_modifiedAt"
      :label="$t('rightNow.modified_at')"
    />
  </AbstractFilter>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import AbstractFilter from '@/components/filter/AbstractFilter'
import Datepicker from '@/components/form/Datepicker'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'NewsOfTheDayFilter',
  components: {
    Input,
    Datepicker,
    AbstractFilter
  },
  data () {
    return {
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.RIGHT_NOW_PERMISSIONS
    }
  },
  computed: {
    filter () {
      return this.$store.getters['newsOfTheDay/filter']
    }
  },
  methods: {
    filterNewsOfTheDay () {
      this.$store.commit('newsOfTheDay/setPage', 1)
      this.$store.dispatch('newsOfTheDay/fetch')
    },
    resetFilter () {
      this.$store.commit('newsOfTheDay/resetFilter')
      this.$store.dispatch('newsOfTheDay/fetch')
    }
  }
}
</script>

<style lang="scss">
.filter-panel-news-of-the-day {
  @include bp(7) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include bp(12) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
